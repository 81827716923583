import React, { useState, useEffect } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { RequestForm } from "../../components/form";
import { useMedia } from "use-media";
import { AppsIntegrations } from "../conversational-ai-platform";

const TopImage = require("../../assets/img/home_page_assist/k_ai_landing.mp4");
const TopImage_mob = require("../../assets/img/home_page_assist/k_ai_landing.mp4");
const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/clients_logo_mob.svg");
const ad_one = require("../../assets/img/home_page_assist/knowledge_ad_1.png");
const ad_two = require("../../assets/img/home_page_assist/knowledge_ad_2.png");
const ad_three = require("../../assets/img/home_page_assist/knowledge_ad_3.png");
const ad_four = require("../../assets/img/home_page_assist/knowledge_ad_4.png");
const ad_one_mob = require("../../assets/img/home_page_assist/knowledge_ad_mob_1.png");
const ad_two_mob = require("../../assets/img/home_page_assist/knowledge_ad_mob_2.png");
const ad_three_mob = require("../../assets/img/home_page_assist/knowledge_ad_mob_3.png");
const ad_four_mob = require("../../assets/img/home_page_assist/knowledge_ad_mob_4.png");
const enterprise_one = require("../../assets/img/home_page_assist/enterprise_success_1.png");
const enterprise_two = require("../../assets/img/home_page_assist/enterprise_success_2.png");
const enterprise_three = require("../../assets/img/home_page_assist/enterprise_success_3.png");
const security = require("../../assets/img/home_page_assist/security_3.svg");
const goto_logo = require("../../assets/images/testimonials/goto_logo_mob.svg");
const gabi = require("../../assets/img/home_page_assist/gabriel_knowledge_ai.png");
const goto_manager_mobile = require("../../assets/images/testimonials/goto_manager_2.png");

const servicenow = require("../../assets/images/icons-web/servicenow.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const solarwinds = require("../../assets/images/icons-web/solarwinds.svg");
const service_desk = require("../../assets/images/icons-web/service_desk.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const orangehrm = require("../../assets/images/icons-web/orangehrm.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const gusto = require("../../assets/images/icons-web/gusto.svg");
const sap = require("../../assets/images/icons-web/sap_successfactors.svg");
const intune = require("../../assets/images/icons-web/ms_intune.svg");
const mobileiron = require("../../assets/images/icons-web/mobile_iron.svg");
const hexnode = require("../../assets/images/icons-web/hexnode.svg");
const citrix = require("../../assets/images/icons-web/citrix.svg");

const integrationsApps = [
  {
    header: "19+ ITSM",
    userApps: [
      {
        icon: servicenow,
        appName: "ServiceNow",
        url: "/conversational-ai-platform/servicenow-chatbot",
      },
      {
        icon: freshservice,
        appName: "Freshservice",
        url: "/conversational-ai-platform/freshservice-chatbot",
      },
      {
        icon: jira,
        appName: "Jira Service Desk",
        url: "/conversational-ai-platform/jira-service-desk-chatbot",
      },
      {
        icon: solarwinds,
        appName: "SolarWinds",
        url: "/conversational-ai-platform/solarwinds-chatbot",
      },
      {
        icon: service_desk,
        appName: "ServiceDesk Plus",
        url: "/conversational-ai-platform/servicedesk-plus-chatbot",
      },
    ],
  },
  {
    header: "13+ HR",
    userApps: [
      {
        icon: bamboo,
        appName: "BambooHR",
        url: "/conversational-ai-platform/bamboohr-chatbot",
      },
      {
        icon: orangehrm,
        appName: "OrangeHRM",
        url: "/conversational-ai-platform/orange-hrm-chatbot",
      },
      {
        icon: zoho_people,
        appName: "Zoho People",
        url: "/conversational-ai-platform/zoho-people-chatbot",
      },
      {
        icon: gusto,
        appName: "Gusto",
        url: "/conversational-ai-platform/gusto-chatbot",
      },
      {
        icon: sap,
        appName: "SAP SuccessFactors",
        url: "/conversational-ai-platform/sap-successfactors-chatbot",
      },
    ],
  },
  {
    header: "15+ Access Management",
    userApps: [
      {
        icon: okta,
        appName: "Okta",
        url: "/conversational-ai-platform/okta-chatbot",
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD",
        url: "/conversational-ai-platform/active-directory-chatbot",
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud",
        url: "/conversational-ai-platform/jumpcloud-chatbot",
      },
      {
        icon: office365,
        appName: "Microsoft Office 365",
        url: "/conversational-ai-platform/office-365-chatbot",
      },
      {
        icon: onelogin,
        appName: "Onelogin",
        url: "/conversational-ai-platform/onelogin-chatbot",
      },
    ],
  },
  {
    header: "09+ MDM",
    userApps: [
      {
        icon: intune,
        appName: "Microsoft Intune",
        url: "/conversational-ai-platform/microsoft-intune-chatbot",
      },
      {
        icon: mobileiron,
        appName: "MobileIron",
        url: "/conversational-ai-platform/mobileiron-chatbot",
      },
      {
        icon: hexnode,
        appName: "Hexnode",
        url: "/conversational-ai-platform/hexnode-chatbot",
      },
      {
        icon: citrix,
        appName: "Citrix",
        url: "/conversational-ai-platform/citrix-chatbot",
      },
      {
        icon: service_desk,
        appName: "ManageEngine MDM",
        url: "/conversational-ai-platform/manageengine-mdm-chatbot",
      },
    ],
  },
];
export default function DialogDesigner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Empower Your Enterprise Employee Support with a Gen AI & Automation"
        description="Elevate your enterprise efficiency with the all-in-one employee support automation platform solution. Streamline operations, reduce costs, and boost productivity."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="Empower Your Enterprise Employee Support with a Gen AI & Automation"
        ogDescription="Elevate your enterprise efficiency with the all-in-one employee support automation platform solution. Streamline operations, reduce costs, and boost productivity."
      />
      <Container
        additionalClass={"feature-display-none"}
        additionalClassParent={"indvidual_features_parent_cont"}
      >
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <TopFormWithImage
            video={TopImage}
            altImage={"knowledge ai"}
            video_mob={TopImage_mob}
            additionalClassImage={"width-100"}
            additionalClassSection={"knowledge-ai-landing-section"}
            isVideo={true}
            isMarginTopZero={true}
          >
            {isSmall ? (
              <TopFormWithImage.Header>
                The complete employee experience platform for the Enterprise
              </TopFormWithImage.Header>
            ) : (
              <TopFormWithImage.Header>
                The complete employee experience <br /> platform for the
                Enterprise
              </TopFormWithImage.Header>
            )}

            <TopFormWithImage.Content paddingRemovePara={true}>
              The only solution that combines an AI chatbot, knowledge AI, app
              workflow automation, and proactive live support—so you can keep
              costs low, support teams happy, and users satisfied.
            </TopFormWithImage.Content>
            <RequestForm isFooterForm={false} />
          </TopFormWithImage>
          <div
            className={`float-left w-100 ${isSmall ? "mb-40 mt-40" : "mb-40"}`}
          >
            <div className="container mb-2">
              <h5
                className={`text-center font-section-normal-text-medium-customer-banner ${
                  isSmall ? "mb-3" : "margin-bottom-20 "
                }`}
              >
                Join hundreds of industry leaders
              </h5>{" "}
              {isSmall ? (
                <img className="" src={bannerImageMobile} />
              ) : (
                <img className="  d-flex m-auto" src={customerBanner} />
              )}
            </div>
          </div>
          <EasyPlatform />
          <GotoTesti />
          <AppsIntegrations
            additionalClass="apps-integration-knowledge-ai"
            header={
              isSmall ? (
                <h3 className="font-section-sub-header mb-2 color-light-black">
                  Connect with 100+ apps and automate tasks instantly
                  <br />
                  <span className="font-text-medium-apps-ci color-light-grey">
                    {" "}
                    No coding.
                  </span>
                </h3>
              ) : (
                <>
                  <h3 className="font-section-sub-header mb-3">
                    Connect with 100+ apps and automate tasks instantly
                    <br />
                    <span className="font-text-medium-apps-ci color-light-grey">
                      {" "}
                      No coding.
                    </span>
                  </h3>
                </>
              )
            }
            footer={
              <>
                Also available CRM, Document Management, Expense Management,
                Email Management, SMS Management, Notifications, and
                Collaboration Apps.
              </>
            }
            apps={integrationsApps}
          />
          <GradeSecurity />
          <EnterpriseSucess />
          <div className="isfooter_form">
            <RequestForm isFooterForm={true} />
          </div>
        </Layout>
      </Container>
    </>
  );
}

const EasyPlatform = () => {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left easy-platform-section-container">
      <div className="container">
        <h4 className="font-section-sub-header text-align-center mb-2">
          A single, powerful, easy-to-use platform
        </h4>
        {isSmall ? (
          <p
            className={`font-section-normal-text-testimonials text-align-center line-height-16`}
          >
            Get all your automation, conversations, workflows, and reporting
            working together seamlessly. Maximize productivity with one central
            workspace that connects your bots, inbox, and workflows.
          </p>
        ) : (
          <p
            className={`font-section-normal-text-testimonials text-align-center line-height-16 `}
          >
            Get all your automation, conversations, workflows, and reporting
            working together seamlessly.
            <br /> Maximize productivity with one central workspace that
            connects your bots, inbox, and workflows.
          </p>
        )}

        <div className="grid-container-easy-platform">
          <div className="grid-item-one-easy-platform w-100 position-relative">
            <div className="position-absolute content-item-easy">
              <h5
                className={`font-section-sub-header-bold-v2 ${
                  isSmall ? "mb-1" : ""
                }`}
              >
                AI Chatbot you can scale
              </h5>
              <p className={`font-easy-normal-text ${isSmall ? "mb-0" : ""}`}>
                Build advanced chatbot with Generative AI capabilities to
                provide proactive, highly customized AI experience at scale.
              </p>
            </div>
            {isSmall ? <img src={ad_one_mob} /> : <img src={ad_one} />}
          </div>
          <div className="grid-item-three-easy-platform w-100 position-relative">
            <div className="position-absolute content-item-easy">
              <h5
                className={`font-section-sub-header-bold-v2 ${
                  isSmall ? "mb-1" : ""
                }`}
              >
                Knowledge AI
              </h5>
              <p className={`font-easy-normal-text ${isSmall ? "mb-0" : ""}`}>
                Knowledge AI leverages the power of Large Language Model (LLM)
                and Generative AI turning your knowledge into generative AI
                chats for user queries.
              </p>
            </div>
            {isSmall ? <img src={ad_two_mob} /> : <img src={ad_two} />}
          </div>
          <div className="grid-item-two-easy-platform w-100 position-relative">
            <div className="position-absolute content-item-easy">
              <h5
                className={`font-section-sub-header-bold-v2 ${
                  isSmall ? "mb-1" : ""
                }`}
              >
                Auto-resolution with app workflows
              </h5>
              <p className={`font-easy-normal-text ${isSmall ? "mb-0" : ""}`}>
                Connect bots with apps and automate your most repetitive process
                and workflows to free your support agents.
              </p>
            </div>
            {isSmall ? <img src={ad_three_mob} /> : <img src={ad_three} />}
          </div>
          <div className="grid-item-four-easy-platform w-100 position-relative">
            <div className="position-absolute content-item-easy">
              <h5
                className={`font-section-sub-header-bold-v2 ${
                  isSmall ? "mb-1" : ""
                }`}
              >
                Shared Inbox with live chat
                <sup
                  style={{
                    top: "-0.1em ",
                    color: "#E05959",
                    fontSize: "17px",
                  }}
                >
                  *
                </sup>
              </h5>
              <p className={`font-easy-normal-text ${isSmall ? "mb-0" : ""}`}>
                Engage users with confidence using shared inbox for live chat,
                bot transfers, actions, and much more.
              </p>
            </div>
            {isSmall ? <img src={ad_four_mob} /> : <img src={ad_four} />}
          </div>
        </div>
        <span className="font-section-webinar-date float-right shared-inbox-knowledge-coming-soon">
          {" "}
          <sup
            style={{
              top: "-0.1em ",
              color: "#E05959",
              fontSize: "17px",
            }}
          >
            *
          </sup>
          Coming soon
        </span>
      </div>
    </section>
  );
};
const GotoTesti = () => {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left goto-knowledge-ai-section">
      {isSmall ? (
        <div className="container">
          <div className="flex-container-goto-knowledge-ai">
            <div className="flex-item-goto-knowledge-ai flex-2">
              <p className="font-goto-testi-normal line-height-18 mb-2">
                “After researching{" "}
                <span className="font-goto-testi-normal-medium color-black">
                  5 different chatbot
                </span>{" "}
                solutions, we decided to go ahead with{" "}
                <span className="font-goto-testi-normal-medium color-black">
                  Workativ&nbsp;
                </span>
                for its’ easy integration with systems that we use internally at{" "}
                <span className="font-goto-testi-normal-medium color-black">
                  GoTo.”
                </span>
              </p>
              <div className="flex-item-goto-knowledge-ai d-flex align-items-center justify-content-between">
                <div>
                  <h3 className="font-section-bold-small-text">
                    Gabriel Grecco
                  </h3>
                  <h2 className="font-section-automate-normal-text-testimonials mb-3">
                    Global Manager - IT Service Desk
                  </h2>
                  <img src={goto_logo} className="w-20" />
                </div>
                <img src={goto_manager_mobile} className="w-30" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="flex-container-goto-knowledge-ai">
            <div className="flex-item-goto-knowledge-ai flex-2">
              <p className="font-goto-testi-normal line-height-18 mb-2">
                “After researching{" "}
                <span className="font-goto-testi-normal-medium color-black">
                  5 different chatbot
                </span>{" "}
                solutions, we decided to go ahead with{" "}
                <span className="font-goto-testi-normal-medium color-black">
                  Workativ&nbsp;
                </span>
                for its’ easy integration with systems that we use internally at{" "}
                <span className="font-goto-testi-normal-medium color-black">
                  GoTo.”
                </span>
              </p>
              <h3 className="font-section-bold-small-text">Gabriel Grecco</h3>
              <h2 className="font-section-automate-normal-text-testimonials mb-3">
                Global Manager - IT Service Desk
              </h2>
              <img src={goto_logo} className="w-12" />
            </div>
            <div className="flex-item-goto-knowledge-ai flex-1">
              <img src={gabi} />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
const GradeSecurity = () => {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left grade-security-section-container">
      <div className="container">
        <h2 className="font-section-sub-header text-align-center m-auto">
          Enterprise-grade security
        </h2>
        <div className="grid-container-grade-security">
          <div
            className={`d-flex flex-column align-items-center  justify-content-start mb-0 pb-0 ${
              isSmall ? "w-100" : "width-fit-content"
            }`}
          >
            <h3 className="font-section-normal-text-medium-link">GDPR</h3>
            <p className="font-section-normal-text-testimonials text-align-center line-height-18">
              Data portability and deletion available for all Workativ workspace
              data.
            </p>
          </div>
          <div
            className={`d-flex flex-column align-items-center  justify-content-start mb-0 pb-0 ${
              isSmall ? "w-100" : "width-fit-content"
            }`}
          >
            <h3 className="font-section-normal-text-medium-link">
              Data Protection
            </h3>
            <p className="font-section-normal-text-testimonials text-align-center line-height-18">
              AES 256 bit encryption with 1,024-bit key-strength for data at
              Rest and TLS encryption for data in transit.
            </p>
          </div>
          <div
            className={`d-flex flex-column align-items-center  justify-content-start ${
              isSmall ? "w-100" : "width-fit-content"
            }`}
          >
            <img src={security} className="w-35" />
            <h3 className="font-section-normal-text-medium-link text-align-center">
              International Organization for Standardization
            </h3>
            <p className="font-section-normal-text-testimonials text-align-center line-height-18">
              Information Security Management
              <br /> System{" "}
              <span className="font-section-normal-text-testimonials-medium color-black">
                ISO/IEC 27001:2013
              </span>{" "}
              Certified <br />
              Company
            </p>
          </div>
          <div
            className={`d-flex flex-column align-items-center  justify-content-center  ${
              isSmall ? "w-100" : "width-fit-content"
            }`}
          >
            <h3 className="font-section-normal-text-medium-link">
              Access Controls
            </h3>
            <p className="font-section-normal-text-testimonials text-align-center line-height-18">
              Workativ implements role-based access through IAM that enforces
              segregation of duties, two-factor authentication, and end-to-end
              audit trails, ensuring access is in accordance with security
              policy.
            </p>
          </div>
        </div>
        <span className="d-flex justify-content-center">
          <a
            className="font-section-normal-text-medium-link text-decoration-underline text-underline-offset text-align-center color-anchor-blue m-auto"
            href="https://workativ.com/security"
          >
            Learn more about our security
          </a>
        </span>
      </div>
    </section>
  );
};
const EnterpriseSucess = () => {
  const isSmall = useMedia({ maxWidth: "520px" });
  const [imageHeight, setImageHeight] = useState("auto");

  useEffect(() => {
    const setImagesHeight = () => {
      const firstImage = document.querySelector(
        ".flex-container-enterprise-success img"
      );
      if (firstImage) {
        const height = `${firstImage.clientHeight}px`;
        setImageHeight(height);
      }
    };

    setImagesHeight();
    window.addEventListener("resize", setImagesHeight);

    return () => {
      window.removeEventListener("resize", setImagesHeight);
    };
  }, []);
  return (
    <section className="w-100 float-left enterprise-success-section-container">
      <div className="container">
        <h2 className="font-section-sub-header text-align-center">
          Enterprise services dedicated to your success
        </h2>
        <div className="flex-container-enterprise-success d-flex mt-4">
          <div>
            <img src={enterprise_one} className="mb-32px" />
            <h3 className="font-section-normal-text-medium-link text-align-center">
              Team onboarding
            </h3>
            <p className="font-section-small-text text-align-center">
              Drive more value, faster, with one-on-one expert guidance from
              your Onboarding Specialist, to set you on a path to success.
            </p>
          </div>
          <div>
            <img
              src={enterprise_two}
              className="mb-32px"
              style={isSmall ? null : { height: imageHeight }}
            />
            <h3 className="font-section-normal-text-medium-link text-align-center">
              Ongoing Success
            </h3>
            <p className="font-section-small-text text-align-center">
              Your best business outcomes will be driven from partnering with
              your success manager to plan and optimize your workflows.
            </p>
          </div>
          <div>
            <img
              src={enterprise_three}
              className="mb-32px"
              style={isSmall ? null : { height: imageHeight }}
            />
            <h3 className="font-section-normal-text-medium-link text-align-center">
              Flexible Support
            </h3>
            <p className="font-section-small-text text-align-center">
              The fastest response times our team has to offer—escalate your
              most critical questions to our Premier Support team, day or night.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
